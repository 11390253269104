import packageJson from '../../package.json'
import { backUrl } from "./backUrl"

export const common = {
  appName: packageJson.name || "app",
  appUrl: backUrl,
  projectName: packageJson.title,
  projectLogo: require('@/assets/mainlogo.svg'),
  dateFormat: 'DD.MM.YYYY',
  localStorageKey: process.env.VUE_APP_LOCAL_STORAGE_KEY,
  internal_email: process.env.VUE_APP_INTERNAL_EMAIL,
  external_email: process.env.VUE_APP_EXTERNAL_EMAIL,
  capStands: [''], //стенды для заглушки 'Prod', 'Stage'
  // logoutLink: buildLogoutLink(),
  adminUrl: "/admin", // На каком адресе "сидит" админский раздел, нужно для шаблонизации
  reference: {
    maxWidth: 1920,
  },
}

export const resourses = {
  helpdesk: 'https://helpdesk.mts.ru',
}

export const monitoring = {
  dsn: process.env.VUE_APP_SENTRY_DSN,
}

export const centrifuge = {
  secret: process.env.VUE_APP_CENTRIFUGE,
}

export const matomo = {
  host: 'https://matomo.obs.mts.ru',
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,
}

export const envs = {
  local: "localhost",
  dev: "dev.beast.mts.ru",
  stage: "demo.beast.mts.ru",
  prod: "university.hr.mts.ru",
  external: host => [ "education", "external" ].some(h => host.includes(h)),
}

/**
 * Логины исключения для перенаправления из мобильной версии админки
 */
export const whiteList = Object.entries(process.env).reduce((acc, [key, value]) => {
  if (key.includes('WHITE_LIST')) acc.push(value)

  return acc
}, [])



const _default = {
  common,
  resourses,
  monitoring,
  centrifuge,
  matomo,
  envs,
  whiteList
}

export const initConfig = {
  install: Vue => {
    Object.defineProperty(Vue.prototype, "$config", {
      enumerable: true,
      configurable: false,
      writable: false,
      value: _default,
    })

    /**
     * Устанавливаем заголовок документа
     */
    document.title = common.projectName
  }
}

export default _default
